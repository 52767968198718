import React from 'react'
import './traits.css'
import {BiCheck} from 'react-icons/bi'
import {FaThumbsUp} from 'react-icons/fa'
import {FaThumbsDown} from 'react-icons/fa'

const Services = () => {
  return (
    <section id='Services'>
      <h5>My Work</h5>

      <div className="services">
        <h2>Experience</h2>
      </div>

      <div className="container services__container">

      <article className='service'>
          <div className='service__head'>
            <h3>Part-Time Job | IT Consultant at Miles IT | San Jose, CA </h3>
            <h3>August 2024 - Present</h3>
          </div>

          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Provided onsite IT consulting and technical support for small to medium-sized businesses, ensuring efficient technology usage.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Diagnosed and resolved hardware, software, and network issues for desktops and servers.</p>              
            </li> 

             <li>
              <FaThumbsUp className='service__list-icon'/>
              <p> Maintained close proximity to service areas, ensuring quick and efficient response to customer needs.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Documented technical work and communicated consistently with customers to ensure satisfaction.</p>
            </li>

          </ul>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h3>Freelance | Software Engineer at Outlier | Remote </h3>
            <h3>May 2024 - Present</h3>
          </div>

          <ul className='service__list'>
            

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Contributed to training generative artificial intelligence models to improve their coding generation capabilities.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Evaluated the quality of AI-generated code, providing detailed summaries and reports of the rationale.</p>              
            </li> 

             <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Solved various coding problems, writing functional and efficient code to aid AI learning.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Developed robust test cases to ensure the efficiency and effectiveness of AI-generated code.</p>
            </li>

          </ul>
        </article>



        <article className='service'>
          <div className='service__head'>
            <h3>Contract | Software Developer at DataAnnotation | Mountain View, CA </h3>
            <h3>May 2024 - July 2024</h3>
          </div>

          <ul className='service__list'>
            {/* <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>As an AI Chatbot Trainer and Programmer at DataAnnotation, I enhance chatbot coding capabilities by conducting 
                code reviews and solving complex programming challenges similar to LeetCode and HackerRank. I utilize languages like 
                JavaScript, Python, and SQL, and ensure that each solution is clearly articulated to improve the bots' autonomous functionality.
              </p>
            </li> */}

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Monitored and measured the progress of chatbots, ensuring they met development benchmarks.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Collaborated with a team to train AI chatbots in coding, enhancing their capabilities and accuracy.</p>              
            </li> 

             <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Wrote and evaluated code, maintaining high standards of quality and functionality.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Clearly explained the logic and methodology behind my coding solutions, contributing to the knowledge base of the team.</p>
            </li>

          </ul>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h3>Internship | Software Engineer at Radical AI | New York, NY </h3>
            <h3>April 2024 - July 2024</h3>
          </div>

          <ul className='service__list'>
            <li>
              {/* <FaThumbsUp className='service__list-icon'/> */}
              <p>As a Software Engineer at Radical AI, I am leveraging
                  technologies such such as OpenAI, Node.js and React to develop ReX, an AI
                  Coach who serves as a steadfast career companion for learners, offering
                  personalized coaching, mentorship, and support throughout the various
                  phases of their career lifecycle.
              </p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Engage in the front-end development, crafting the UI/UX elements of the AI applications.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Collaborate with AI Engineers and Product Designers to integrate AI functionalities.</p>              
            </li> 

             <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Test and debug applications, ensuring reliability and resilience of the AI platform before deployment.</p>
            </li>

          </ul>
        </article>

        <article className='service'>
          <div className='service__head'>
            <h3>Part-time Job | Logistics Associate at MPCM Inc. | San Jose, CA </h3>
            <h3>January 2024 - August 2024</h3>
          </div>

          <ul className='service__list'>
            <li>
              {/* <FaThumbsUp className='service__list-icon'/> */}
              <p>Manage incoming and outgoing deliveries for Tesla and other automotive companies, both domestic and international, 
                overseeing all aspects of the logistics process. Maintain an up-to-date inventory list, ensuring accurate tracking and timely restocking. 
                Establish effective communication with clients to understand and meet their specific requirements, building strong professional relationships.
                </p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Customer Service</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Teamwork and Team Collaboration</p>              
            </li> 

             <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Utilize Microsoft Excel, Quickbooks</p>
            </li>

          </ul>
        </article>
        {/* END OF UI/UX */}
        {/* <article className='service'>
          <div className='service__head'>
            <h3>Weakness</h3>
          </div>

          <ul className='service__list'>
            <li>
              <FaThumbsDown className='service__list-icon'/>
              <p>Public Speaking: Unusual stutter when speaking in public such 
                as a presentation or an interview. 
                Tend to be very nervous and lose track of thought process when 
                speaking nervously and causes speaking rapidly.</p>
            </li>

            <li>
              <FaThumbsDown className='service__list-icon'/>
              <p>Lack of Experience in a specific area: Lack of knowledge in 
                some coding languages and 
                would usually struggle in critical thinking in coding 
                challenges. This leads to my strength curiosity where I'm willing to explore
                new skills beyond my limit.</p>
            </li>

            <li>
              <FaThumbsDown className='service__list-icon'/>
              <p>Overthinking: Overthink about a task that must be done or something that is approaching a deadline. 
                This weakness leads to the strength determination.
                </p>
              
            </li>
          </ul>
        </article> */}
        {/* END OF WEB DEVELOPMENT */}
        {/* <article className='service'>
          <div className='service__head'>
            <h3>Content Creation</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ASDASDASDASD</p>
            </li>
          </ul>
        </article> */}
        {/* END OF CONTENT CREATION */}
      </div>
    </section>

  )
}

export default Services