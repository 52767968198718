import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio7.PNG'
import IMG2 from '../../assets/DBMSproject.PNG'
import IMG3 from '../../assets/MarketplaceProject.PNG'
import IMG4 from '../../assets/Piano.PNG'
import IMG5 from '../../assets/flappybird.PNG'
import IMG6 from '../../assets/JARapp.PNG'
import IMG7 from '../../assets/MathHuntLogo.PNG'
import IMG8 from '../../assets/PantryAI.PNG'
import {FaThumbsUp} from 'react-icons/fa'

const Portfolio = () => {
  return (
    <section id='Portfolio'>
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className='container portfolio__container'>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG7} alt=""/>
          </div>
          <h3>Math Hunt Game</h3>
          <small> Unlock your potential with Math Hunt. Dive into this simple math puzzle game where precision meets speed. 
            Perfect for sharpening your basic math skills. </small>
          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Developed a React Native mobile game focusing on math skills and math problem solving.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Implemented a high-score tracker with a timer based gameplay optimized for iOS platform.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Designed family friendly UI/UX, ensuring an intuitive and engaging user experience for all ages.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Deployed the game to Apple’s App Store utilizing Expo, Apple Developer, and Apple Connect.</p>
            </li>
          </ul>
          <div className='portfolio__item-cta'>
            <a href='https://apps.apple.com/us/app/math-hunt-sum/id6495973034' className='btn'>Download Link</a>
            <a href='https://www.youtube.com/shorts/iEwzQuQO36A' className='btn btn-primary' target='_blank'>Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG5} alt=""/>
          </div>
          <h3>Angry Flappy Bird Game</h3>
          <small> This game combines the gameplay of Flappy Bird with the Angry Birds theme. Players navigate through obstacles in the sky, 
            aiming to achieve the highest score. A special feature of the Angry Bird/Flappy Bird skin customization has been implemented with 
            just a simple click at the menu. The project features an immersive graphics, sound effects, and smooth gameplay. 
          </small>
          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Developed a simple web game using JavaScript and HTML.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Implemented a high-score tracker gameplay and a special feature that allows the user to customize the bird's appearance.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Designed family friendly UI/UX, ensuring an intuitive and engaging user experience for all ages.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Deployed the game to public web server using Hostinger.</p>
            </li>
          </ul>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/AnhVuong146/AngryFlappyBirdGame' className='btn'>Github</a>
            <a href='https://angryflappybirdav.com/' className='btn btn-primary' target='_blank'>Play Game</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG8} alt=""/>
          </div>
          <h3>PantryAI</h3>
          <small>PantryAI is a mobile application designed to streamline kitchen management and enhance meal planning efficiency. 
            Utilizing the power of artificial intelligence, PantryAI helps users manage their pantry inventory by 
            tracking expiry dates and quantities, and suggesting recipes based on available ingredients. 
            The app aims to reduce food waste and assist users in making informed decisions about their meal preparations.   
          </small>
          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>IntegratedOpenAI's API for dynamic recipe suggestions.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Implemented database using flask and SQL for pantry items storage. </p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Utilized ReactNative as the framework and TypeScript/JavaScript for UI.</p>
            </li>
          </ul>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/deptrajvjppro/pantryaiv2' className='btn'>Github</a>
            <a href='https://www.youtube.com/watch?v=GDMsRPdhr2g' className='btn btn-primary' target='_blank'>Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG6} alt=""/>
          </div>
          <h3>JAR Chat Application</h3>
          <small>The JAR Chat Application is a final project designed for the Computer Network and Protocols class. 
            This innovative platform facilitates multi-user participation, 
            enabling seamless communication among participants. Its design is both intuitive and straightforward, 
            requiring only a username for users to engage in real-time conversations.   
          </small>
          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Developed a Chat Application using JavaScript and HTML.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Utilized SocketIO for dynamic and live chatting capabilities.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Designed on the robust Python Flask framework, HTML, and CSS for styling and ensuring a smooth and efficient user experience.</p>
            </li>
          </ul>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/AnhVuong146/AnhVuong146-CMPE148-Chat-Application-Project' className='btn'>Github</a>
            <a href='https://youtu.be/Y48STZ4Q73s' className='btn btn-primary' target='_blank'>Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG2} alt=""/>
          </div>
          <h3>Database Management System Web App Project </h3>
          <small>This is a web application group project that allows the users to implement their todo-list or todo-tasks. 
            The main idea of the project is the implementation of our database managemnet system featuring DB broser SQLite 
            that stores data as the backend of the web application.
          </small>
          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Implemented a Python Flask back-end that manages To-do list on the user's end</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Utilized Oracle and DB Browser for SQLite as the database managemnet systems.</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Designed UI/UX using HTML and CSS, ensuring user-friendly experience and simple interactions.</p>
            </li>
          </ul>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/AnhVuong146/CS157A-Project' className='btn'>Github</a>
            <a href='https://www.youtube.com/watch?v=pi74_TlZzyY' className='btn btn-primary' target='_blank'> Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG3} alt=""/>
          </div>
          <h3>Marketplace Web App Project</h3>
          <small>This is a group Web Application Project that features both front-end and back-end skills. This website functions similar to 
            online shopping websites such as Ebay and Amazon that allows users to shop online and displays a list of items for users to browse, 
            leave a review, search, and add to shopping cart. 
          </small>
          <ul className='service__list'>
            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Implemented a Python Flask back-end that manages users login and register credentials</p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Collaborated with a team of four, ensuring teamwork by using GitHub as the version control, and that the project milestones were met. </p>
            </li>

            <li>
              <FaThumbsUp className='service__list-icon'/>
              <p>Designed UI/UX using HTML and CSS, ensuring user-friendly experience and simple interactions.</p>
            </li>
          </ul>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/AnhVuong146/CMPE131-Marketplace' className='btn'>Github</a>
            <a href='https://www.youtube.com/watch?v=QWwT7A-CNJo' className='btn btn-primary' target='_blank'> Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG1} alt=""/>
          </div>
          <h3>Portfolio Project</h3>
          <small>Personal Portfolio is a Web Application project designed to provide insight into my strengths, and professional skills for the software tech industry. 
            This project serves as a comprehensive platform, showcasing not only my contact details but also my detailed resume. At its core, the aim is to showcase 
            my expertise in front-end development and highlight my passion for the field.
          </small>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/AnhVuong146/Portfolio' className='btn'>Github</a>
            {/* <a href='https://github.com/anhvuong146' className='btn btn-primary' target='_blank'>Live Demo</a> */}
          </div>
        </article>

         {/* <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG4} alt=""/>
          </div>
          <h3>Youtube Video Editing Piano Project</h3>
          <small>This is a video of myself playing a piano classical "Kiss the Rain" By Yiruma. This project features my hobby along with my video editing skills.</small>
          <div className='portfolio__item-cta'> */}
            {/* <a href='https://youtu.be/NjqdKHhrfBo' className='btn btn-primary' target='_blank'>Youtube</a>
          </div>
        </article> */}

        

        {/* <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG6} alt=""/>
          </div>
          <h3>Genius Fitness UNDER DEVELOPMENT</h3>
          <small>"A fitness application designed to motivate and guide new gym attendees. 
            This application allows users to select workouts tailored to their gender, ensuring a better fit for their specific needs. 
            The primary feature is a front-end library that enables users to browse and search for desired exercises. 
            If users are uncertain about how to execute a chosen exercise, they can access linked short YouTube videos for step-by-step guidance."  </small>
          <div className='portfolio__item-cta'>
            <a href='https://github.com/anhvuong146' className='btn'>Github</a>
            <a href='https://github.com/anhvuong146' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article> */}

        
      </div>

    </section>

  )
}

export default Portfolio