import React from 'react'
// import CoverLetter from '../assets/CoverLetter.pdf' 
import InternshipResume from "../../assets/InternshipResume.pdf";
import CV from '../../assets/CV.pdf'

const CTA = () => {
  return (
    <div className='cta'>
        <a href={InternshipResume} className="btn btn-primary">View Resume</a>
        {/* <a href={CV} download className='btn'>Download CV</a> */}
        <a href="#contact" className='btn btn-primary'>Contact Me</a>
    </div>
  )
}

export default CTA